@font-face {
  font-family: 'Felidae';
  src: url('./assets/fonts/Felidae.ttf');
}

@font-face {
  font-family: 'Alarus';
  src: url('./assets/fonts/alaruss.ttf');
}

@font-face {
  font-family: 'Apex';
  src: url('./assets/fonts/mr_apexmk3_extralight.ttf');
}

@font-face {
  font-family: 'Jasmine';
  src: url('./assets/fonts/mr_kindlyjasmineg.otf');
}

:root {
  --bg-color: #0A0806;
  --font-color: #FFFBF2;
  --secondary-color: #FCB201;
  --header-font-size: 1.5rem;
  --header-font-family: Felidae;
  --main-font-size: 2rem;
  --main-font-family: Oswald, sans-serif
}

html {
  overflow-x: hidden;
}

body {
  background-color: var(--bg-color);
  padding: 0px 150px;
  color: var(--font-color);
  font-size: var(--main-font-size);
  font-family: var(--main-font-family);
  background-image: url('https://firebasestorage.googleapis.com/v0/b/terrasa-f2de3.appspot.com/o/%D0%A4%D0%BE%D0%BD%D1%8B%2FPaper.png?alt=media&token=84066e83-0af0-49e0-848e-6c061fb59afa');
  background-repeat: no-repeat;
  background-position:top;
  overflow: hidden;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 110px;
  font-size: var(--header-font-size);
}

nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: auto;
  max-width: 750px;
  flex-grow: 1;
  font-size: 1.4rem;
}

a {
  position: relative;
  text-decoration: none;
  color: var(--font-color);
  padding: 0px ;
}

a::after {
  content: "";
  position: absolute;
  background-color: #FCB201;
  height: 3px;
  width: 0;
  left: 0;
  bottom: -10px;
  transition: 0.5s;
}

a:hover::after {
  width: 100%;
}

button {
  background-color: rgba(0, 0, 0, 0);
  color: var(--secondary-color);
  font-size: var(--header-font-size);
  font-family: var(--main-font-family);
  font-weight: 400;
  padding: 10px 20px;
  border: 2px solid var(--secondary-color);
  cursor: pointer;
  transition: 0.5s;
  position: relative;
  overflow: hidden;
  max-width: 300px;
}

button:hover {
  color: var(--bg-color);
}

button:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: var(--secondary-color);
  transform: scaleX(0);
  transform-origin: left;
  z-index: -1;
  transition: transform 0.5s ease-in-out;
}

button:hover::before{
  transform: scaleX(1);
}

button:active{
  transform: scaleX(0);
}

.home {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.landing {
  display: flex;
  justify-content: center;
  height: 970px;
  flex-grow: 1;
  position: relative;
  top: -50px;
}

.landing-wrapper {
  display: flex;
  justify-content: center;
  flex-grow: 1;
}

.title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

h1 {
  font-size: 12rem;
  margin: 0;
  font-weight: 400;
  font-family: var(--header-font-family);
}

h2 {
  margin: 0 20px;
  font-weight: 600;
  letter-spacing: 0.2rem;
  font-family: var(--header-font-family);
}

h3 {
  font-family: var(--main-font-family);
  font-weight: 100;
  color: #B0AEA8;
}

.subtitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-height: 50px;
  width: 800px;
  margin: 0px auto;
}

.line {
  display: flex;
  flex-grow: 1;
  background-color: #FFFBF2;
  height: 2px;
}

#title-but {
  font-size: 2rem;
  margin: 20px auto;
  width: 260px;
}

.best{
  display: flex;
  flex-grow: 1;
  justify-content: center;
  flex-direction: column;
  margin-top: 50px;
  padding: 0px 90px;
  position: relative;
}

.best-items {
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
}

.star {
  position: absolute;
  top: 100px;
  right: -150px;
}

.looper {
  position: absolute;
  top: 400px;
  left: -150px;
}

.text {
  font-size: 2.3rem;
  font-weight: 300;
  margin: auto;
}

.item-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url(./assets//images/frame.png);
  background-repeat: no-repeat;
  background-position: top;
  margin: 30px 0px;
  cursor: pointer;
}

.item-img {
  margin-top: 30px;
  margin-bottom: 60px;
  width: 314px;
  height: 372px;
  object-fit: cover;
}

.item-abstraction {
  position: absolute;
}

.item-link {
  color: #FCB201;
  font-weight: 100;
  font-size: 1.5rem;
}

.item-title {
  font-size: 2.0rem;
  text-align: center;
  margin: 0px;
}

.itempage-title {
  text-align: center;
}

.list {
  margin: 30px;
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
}

.about-us {
  display: flex;
  justify-content: space-between;
  margin-top: 200px;
}

.about-text {
  display: flex;
  max-width: 700px;
  flex-grow: 1;
  flex-direction: column;
  max-height: 610px;
  justify-content: space-between;
  margin: auto 0px;
}

.about-pics {
  text-align: right;
  position: relative;
}


.interior-img {
  position: absolute;
  left: -150px;
  bottom: -80px;
}

.about-title {
  margin-bottom: 20px;
}

.about-text {
  font-weight: 100;
  margin-bottom: 30px;
}

.gallery {
  margin-top: 300px;
  display: flex;
  flex-direction: column;
  text-align: center;
  position: relative;
  justify-content: center;
}

.slider-img {
  width: 400px;
  height: 350px;
}

.slider-but {
  position: absolute;
  bottom: -190px;
  right: 0px;
}

#slider-prev {
  left: 0px;
}

.looper-2 {
  position: absolute;
  right: -200px;
  top: -450px;
  opacity: 0.7;
}

.insta-but {
  margin: 100px auto;
  padding: 10px 70px;
}

.contacts {
  display: flex;
  justify-content: space-between;
  margin: 200px 0;
}

.contacts-text {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: left;
  max-height: 400px;
  min-width: 430px;
  margin-right: 20px;
}

.phone-number {
  font-family: var(--header-font-family);
  font-weight: 100;
  letter-spacing:0.1cm;
}

.working-days {
  display: flex;
  flex-direction: column;

}

h3 {
  margin: 0;
}

h4 {
  margin: 0;
  font-weight: 300;
}

.map {
  height: 550px;
}

.address {
  font-size: 1.7rem;
}

.book:hover::after {
  width: 0;
}

.footer {
  display: flex;
  justify-content: space-between;
  margin-top: 70px;
}

.logo-wrapper{
  display: flex;
  justify-content: space-between;
  max-width: 300px;
  flex-grow: 1;
}

.name {
  font-size: 2.8rem;
  font-family: var(--header-font-family);
  font-weight: 800;
  margin: auto 0;
}

.footer-wrapper {
  position: relative;
}

.footer-line {
  position: absolute;
  left: -200px;
  top: -40px;
  height: 1px;
  width: 100000px;
  background-color: var(--font-color);
}

.footer-inst {
  margin: auto 0px;
}

.burger-btn {
  width: 45px;
  height: 30px;
  margin-left: 20px;
  position: relative;
  cursor: pointer;
  display: none;
}

.burger-btn::before {
  content: "";
  position: absolute;
  top:0;
  width: 45px;
  background-color: var(--secondary-color);
  height: 3px;
}

.burger-btn::after{
  content: "";
  position: absolute;
  bottom: 0;
  width: 45px;
  background-color: var(--secondary-color);
  height: 3px;
}

.burger-btn span {
  content: "";
  position: absolute;
  top: 13px;
  width: 45px;
  background-color: var(--secondary-color);
  height: 3px;
}

.burger-menu {
  position: fixed;
  top: 0px;
  right: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100;
  transition: 0.5s;
}

.blur {
  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(2px);
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  cursor: pointer;
}

.burger-content {
  width: 300px;
  height: 100%;
  background-color: var(--bg-color);
  position: absolute;
  right: 0px;
  display: flex;
  flex-direction: column;
  padding-top: 50px;
}

ul {
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 70%;
  font-size: 1.5rem;
}

ul li a {
  margin: 20px;
}

li {
  margin: 20px 0px;
}

.burger-looper {
  position: absolute;
  top: 0px;
  left: 0px;
}

.burger-cross {
  position: relative;
  left: 200px;
  cursor: pointer;
  transition: 0.5s;
  margin-right: 50px;
  max-width: 30px;
}

.burger-cross:hover {
  transform: scale(1.5);
}

.items {
  display: flex;
  justify-content: space-between;
  gap: 40px;
  flex-wrap: wrap;
  flex-grow: 1;
  overflow: visible;
}

.meals{
  margin-top: 30px;
  column-count: 2;
  column-rule: solid 2px var(--font-color);
  column-span:none;
  overflow: visible;
}

.meal {
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  margin: 30px 30px;
  break-inside: avoid;
}

.meal-title {
  margin: 0px 0px;
  max-width: 400px;
}

.meal-desc {
  font-size:2rem;
  font-weight: 100;
}

.meal-price {
  color: var(--secondary-color);
  font-family:var(--header-font-family) ;
  font-size: 3rem;
  font-weight: 100;
}

.item-page {
  overflow: visible;
  min-height: 70vh;
}

.loading {
  width: 100;
  height: 70vh;
  display: flex;
  justify-content: center;
}

.designed{
  color: #B0AEA8;
  font-weight: lighter;
  margin: auto;
  position: relative;
  left: -80px;
}

@media screen and (max-width: 1500px) {
  nav {
    display: none;
  }

  #header-book {
    display: none;
  }

  .subtitle {
    width: 600px;
  }

  .burger-btn{
    display: block;
  }

  .best-items {
    flex-direction: column;
  }

  .looper {
    top: 700px;
  }

  .meal-title{
    max-width: 300px;
    font-size: 2rem;
  }

  .meal-text > h3 {
    font-size: 1.5rem;
  }
}



@media screen and (max-width: 1300px) {
  button {
    font-size: 1.5rem;
  }

  h1 {
    font-size: 10rem;
  }

  h2 {
    font-size: 2.5rem;
  }

  .book {
    font-size: 1.5rem;
  }

  .text {
    display: flex;
    justify-content: center;
    font-size: 1.6rem;
  }

  .interior-img{
    max-width: 280px;
  }

  .exterior-img {
    max-width: 380px;
  }

  .about-text {
    font-size: 1.5rem;
    max-width: 450px;
  }

 .meal-title{
  font-size: 1.5rem;
 }

 .logo-wrapper{
  max-width: 200px;
 }

 .logo-wrapper > a{
  display: flex;
 }

 .logo{
  width: 50px;
  margin: auto;
 }

 .name{
    font-size: 2rem;
 }
}

@media screen and (max-width: 1024px) {
  body {
    padding: 0px 80px;
  }

  .interior-img {
    display: none;
  }

  .contacts {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .contacts-text {
    margin-bottom: 50px;
    max-width: 500px;
  }

  #best-title {
    max-width: 500px;
  }

  .meals{
    column-count: 1;
  }

  .designed{
    font-size: 1.5rem;
    left: 0px;
  }
}

@media screen and (max-width: 800px) {
  body {
    padding: 0px 40px;
  }
  .best {
    padding: 0px;
  }

  h1 {
    font-size: 8rem;
  }

  h2 {
    font-size: 2rem;
  }

  h3{
    font-size: 1.5rem;
  }

  h4 {
    font-size: 1.2rem;
  }

  .contacts-text {
    height: 250px;
  }

  .text {
    font-size: 1.5rem;
    display: flex;
    justify-content: center;
    text-align: center;
  }

  .exterior-img {
    max-width: 200px;
  }

  .subtitle {
    max-width: 500px;
  }

  #best-title {
    max-width: 400px;
  }

  .about-text{
    font-size: 1.2rem;
  }

  .item-title{
    font-size: 2rem;
  }

  .item-img {
    width: 236px;
    height: 278px;
  }

  .item-card {
    max-width: 236px;
    margin:30px auto;
    background-size: 82px;
    background-position-y: 10px;
  }

  .item-link{
    font-size: 1.2rem;
  }

  .address {
    font-size: 1.2rem;
  }

  .name {
    font-size: 1.5rem;
  }

  .logo-wrapper {
    max-width: 150px;
  }

  .logo {
    width: 30px;
  }

  .inst-logo {
    width: 30px;
  }
}

@media screen and (max-width:600px) {
  .landing {
    max-height: 90vh;
  }

  h1{
    font-size: 6rem;
  }

  h2{
    font-size: 1.6rem;
  }

  .text {
    font-size: 1.2rem;
  }

  #best-title {
    max-width: 320px;
  }

  .about-text {
    font-size: 1rem;
  }

  .subtitle {
    max-width: 400px;
  }


  .looper-2{
    display: none;
  }

  ul li a {
    margin: 20px;
    font-size: 1.5rem;
  }

  .name{
    display: none;
  }

  .logo-wrapper{
    max-width: 50px;
  }

  .meal-price{
    font-size: 2rem;
  }

  .meal-title{
    font-size: 1.2rem;
  }
}

@media screen and (max-width:400px) {

  body {
    padding: 0px 20px;
  }

  h1 {
    font-size: 4rem;
  }

  h2{
    font-size: 1.2rem;
    margin: 0px 5px;
  }

  h3{
    font-size: 1.2rem;
  }

  .subtitle{
    max-width: 300px;
  }

  button{
    font-size: 1.2rem;
  }

  #best-title {
    max-width: 300px;
  }

  .text {
    font-size: 1rem;
  }

  .about-text {
    font-size: 0.8rem;
  }

  .slider-img {
    width: 350px;
    height: 250px;
  }

  .item-img {
    width: 200px;
    height: 236px;
  }

  .item-card {
    background-size: 70px;
  }

  .best {
    padding: 0px;
  }

  .line {
    max-width: 200px;
  }

  .contacts-text {
    height: 200px;
    max-width: 200px; 
  }

  .meal-text > h3{
    font-size: 1rem;
  }

  .meal-title{
    font-size: 1rem;
  }

  .designed{
    font-size: 1rem;
  }
}
